@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.app{
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  /* place-items: center; */
  /* height: 100vh; */
  background-color: rgba(255, 255, 255,1);
  font-family: 'Raleway', sans-serif;
}

.app_body{
  display: flex;
  background-color: #ededed;
  margin-top: -50px;
  height:90vh;
  width: 90vw;
  box-shadow: -1px 4px 20px -6px rgba(0,0,0,0.75);
}

.app_center{
  text-align: center;
  margin-top: -50px;
}

.app__sidebarButton{
  position: absolute;
  top: 10px;
  left:10px;
  width: 40px;
  height: 40px;
  border: 1px solid red;
}

.sidebar{
  display: inline-grid;
  flex-direction: column;
  flex: 0.35 1;
  background-color: rgba(240, 242, 245,1);
  justify-content: space-between;
  padding: 0px 20px 20px 20px;
  /* padding-left: 40px; */
  /* border: 1px solid red; */
  text-align: center;
  height: 100vh;
  overflow: scroll;
}

.sidebar__img{
  border-radius: 50%;
  border: 1px solid lightgray;
}

ul{
  list-style:none;
}
ul,li{
  margin: 0;
  padding: 0;
}

.sidebar__header{
  /* display: flex; */
  text-align: left;
  /* justify-content: space-between; */
  /* min-width: 10vw; */
}

.sidebar__social{
  margin-top: -10px;
  margin-bottom: 10px;
}
.social:hover{
  cursor: pointer;
  transform: scale(1.1, 1.1);
}
.sidebar__social > li{
  margin-top: 5px;
}

/* .sidebar__header > .MuiSvgIcon-root {
  margin-right: 2vw;
  font-size: 24px !important;
} */

.introduction{
  background-image: url("https://res.cloudinary.com/dhl1cdqch/image/upload/v1612754298/Back_no06u6.jpg");
  background-repeat: no-repeat;
  background-position: right;
  height: 800px;
  z-index: 2;
}

.introduction__carousel{
  position: relative;
  top: 100px;

}

.introduction__callToAction{
  border:3px solid rgba(240, 242, 245,1);
  background-color: rgba(240, 242, 245,1);
  border-radius: 5px;
  width: 200px;
  text-align: center;
}

.about{
  background-image: url("https://res.cloudinary.com/dhl1cdqch/image/upload/v1612766759/usb-technology-wallpaper-preview_ksegsn.jpg");
  background-repeat: no-repeat;
  background-position: bottom right;
  /* border: 1px solid green; */
  width: 80vw;
  height: 800px;
}

.about__paragraph{
  position: relative;
  width: 55vw;
  height: 35vh;
  margin: 50px;
  /* overflow: scroll; */
}
.about__tech{
  position: relative;
  width: 50vw;
  height: 30vh;
  margin: 50px;
}

.timeline{
  background-image: url("https://res.cloudinary.com/dhl1cdqch/image/upload/v1612861635/bottom_rphunh.jpg");
  background-repeat: no-repeat;
  background-position: bottom right;
  width: 80vw;
  height: 120vh;
  /* border: 1px solid red; */
}

.clock:hover{
  cursor: pointer;
  transform: scale(1.2, 1.2);
}

.timeline__line{
  position: relative;
  top: 60px;
  width: 4px;
  height: 600px;
  border: 4px solid rgba(240, 242, 245,1);
}

.timeline__circle{
  position: relative;
  width: 60px;
  height: 60px;
  /* border: 2px solid rgba(240, 242, 245,1); */
  border-radius: 50%;
}

.one{
  top: -545px;

}
.two{
  top: -490px;
}
.three{
  top: -440px;
}
.four{
  top: -390px;
}
.five{
  top: -340px;
}
.six{
  top: -298px;
}

.timeline__story{
  /* position: relative;
  background-color: rgba(240, 242, 245,1);
  padding: 30px;
  top: -1150px;
  left: 350px;
  width:30vw; */
}
.timeline__heading{
  /* position: relative;
  top: -1170px;
  left: 350px;
  width:40vw; */
}

.timeline__dateOne{
  position: relative;
  top: -890px;
  left: 150px;
}
.timeline__dateTwo{
  position: relative;
  top: -830px;
  left: 150px;
}
.timeline__dateThree{
  position: relative;
  top: -770px;
  left: 150px;
}
.timeline__dateFour{
  position: relative;
  top: -712px;
  left: 150px;
}
.timeline__dateFive{
  position: relative;
  top: -650px;
  left: 150px;
}
.timeline__dateSix{
  position: relative;
  top: -600px;
  left: 150px;
}

.projects{
  /* border: solid 1px green; */
  width: 80vw;
  height: 1900px;
}

.projects__card:hover{
  cursor: pointer;
  transform: scale(1.05, 1.05);
}

.projects__firstRow{
  background-image: url("https://res.cloudinary.com/dhl1cdqch/image/upload/v1612777869/circuit_vlayw3.jpg");
  background-repeat: no-repeat;
  background-position: top left;
}
.projects__secondRow{
  background-image: url("https://res.cloudinary.com/dhl1cdqch/image/upload/v1612784958/circuit_flip_cr4rrp.jpg");
  background-repeat: no-repeat;
  background-position: bottom left;
}

.projects__heading{
  position: relative;
  margin-left: 20px;
  top: -1480px;
  width: 200px;
}
.projects__click:hover{
  cursor: pointer;
}

.main{
  display: inline-grid;
  flex-direction: column;
  /* flex: 0.65; */
  overflow: scroll;
  /* border: 1px solid red; */
  height: 100vh;
}

