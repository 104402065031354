.introduction{
  background-image: url("https://res.cloudinary.com/dhl1cdqch/image/upload/v1612754298/Back_no06u6.jpg");
  background-repeat: no-repeat;
  background-position: right;
  height: 800px;
  z-index: 2;
}

.introduction__carousel{
  position: relative;
  top: 100px;

}

.introduction__callToAction{
  border:3px solid rgba(240, 242, 245,1);
  background-color: rgba(240, 242, 245,1);
  border-radius: 5px;
  width: 200px;
  text-align: center;
}
