.sidebar{
  display: inline-grid;
  flex-direction: column;
  flex: 0.35;
  background-color: rgba(240, 242, 245,1);
  justify-content: space-between;
  padding: 0px 20px 20px 20px;
  /* padding-left: 40px; */
  /* border: 1px solid red; */
  text-align: center;
  height: 100vh;
  overflow: scroll;
}

.sidebar__img{
  border-radius: 50%;
  border: 1px solid lightgray;
}

ul{
  list-style:none;
}
ul,li{
  margin: 0;
  padding: 0;
}

.sidebar__header{
  /* display: flex; */
  text-align: left;
  /* justify-content: space-between; */
  /* min-width: 10vw; */
}

.sidebar__social{
  margin-top: -10px;
  margin-bottom: 10px;
}
.social:hover{
  cursor: pointer;
  transform: scale(1.1, 1.1);
}
.sidebar__social > li{
  margin-top: 5px;
}

/* .sidebar__header > .MuiSvgIcon-root {
  margin-right: 2vw;
  font-size: 24px !important;
} */
