@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.app{
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  /* place-items: center; */
  /* height: 100vh; */
  background-color: rgba(255, 255, 255,1);
  font-family: 'Raleway', sans-serif;
}

.app_body{
  display: flex;
  background-color: #ededed;
  margin-top: -50px;
  height:90vh;
  width: 90vw;
  box-shadow: -1px 4px 20px -6px rgba(0,0,0,0.75);
}

.app_center{
  text-align: center;
  margin-top: -50px;
}

.app__sidebarButton{
  position: absolute;
  top: 10px;
  left:10px;
  width: 40px;
  height: 40px;
  border: 1px solid red;
}
