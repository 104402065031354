.projects{
  /* border: solid 1px green; */
  width: 80vw;
  height: 1900px;
}

.projects__card:hover{
  cursor: pointer;
  transform: scale(1.05, 1.05);
}

.projects__firstRow{
  background-image: url("https://res.cloudinary.com/dhl1cdqch/image/upload/v1612777869/circuit_vlayw3.jpg");
  background-repeat: no-repeat;
  background-position: top left;
}
.projects__secondRow{
  background-image: url("https://res.cloudinary.com/dhl1cdqch/image/upload/v1612784958/circuit_flip_cr4rrp.jpg");
  background-repeat: no-repeat;
  background-position: bottom left;
}

.projects__heading{
  position: relative;
  margin-left: 20px;
  top: -1480px;
  width: 200px;
}
.projects__click:hover{
  cursor: pointer;
}
